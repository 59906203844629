import { postFetch } from '@utils/fetcher'

//In errorInfo we got the componentStack from React, while jsStack is the stack trace from the browser (error.stack)
export async function reportError(error, errorInfo, jsStack, url) {
  // if (import.meta.env.PROD) {
  try {
    const data = await postFetch('/api/clients/report-error', {
      error: error.toString(),
      errorInfo,
      jsStack,
      url,
    })

    return data
  } catch (error) {
    return {
      error: error.data,
    }
  }
  // }
}

export default {
  reportError,
}
