const imagesCDN = import.meta.env.VITE_IMAGES_CDN
const avatarsBucket = import.meta.env.VITE_BUCKET_AVATARS
const imagesBucket = import.meta.env.VITE_BUCKET_IMAGES

/**
 * @typedef {Object} ImageFile An object representing an image file
 * @property {String} name The name of the image (e.g. '<hashed-name>.jpg]')
 * @property {String} containerId The container ID is the id of the entity where the image was uploaded from  (e.g. '123456')
 * @property {String} extension The extension of the image (e.g. 'jpg')
 * @property {String} mime The mime type of the image (e.g. 'image/jpeg')
 * @property {Number} size The size of the image in bytes (e.g. '123456')
 * @property {String} originalFilename  The original filename of the image (e.g. 'cover.jpg')
 * @property {Number} width The width of the image in pixels (e.g. 800)
 * @property {Number} height The height of the image in pixels (e.g. 600)
 * @property {String} blurhash A compact representation of a placeholder for the image. See https://blurha.sh (e.g. 'L5H2EC=PM+yV0g-mq.wG9c010J}I')
 */

/**
 * Given an image path, returns the corresponding signature
 *
 * @param {String} path The image path to get the signature for
 * @returns {string} signature
 */
function getImageSignature(path) {
  // Return base64 encoded path, removing no URL-friendly characters
  return window
    .btoa(path)
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
}

/**
 * Returns an Avatar's absolute url
 * @param {Object} user The user object to get the avatar for
 * @param {String} formatOptions Check here for processing options: https://docs.imgproxy.net/generating_the_url?id=processing-options
 * @returns {string} The absolute url of the avatar
 */
export function getAvatarUrl(user, formatOptions = 'w:400') {
  if (!user) return ''
  // Ensure we get the id from the user object
  const userId = user.id || user._id
  // Build the path to the avatar
  const path = `/${formatOptions}/${avatarsBucket}/${userId}/${user.avatar}`
  // Get the signature for the path
  const signature = getImageSignature(path)

  // Return the absolute url
  return `${imagesCDN}/resize/${signature}${path}`
}

/**
 * Returns an Image's absolute url
 *
 * @param {ImageFile} file The image object to get the url for
 * @param {String} formatOptions Check here for processing options: https://docs.imgproxy.net/generating_the_url?id=processing-options
 *
 * @returns {string} The absolute url for the image
 */
export function getImageUrl(file, formatOptions = 'w:800') {
  if (!file || !file.name || !file.containerId) return ''

  const path = `/${formatOptions}/${imagesBucket}/${file.containerId}/${file.name}`

  const signature = getImageSignature(path)

  return `${imagesCDN}/resize/${signature}${path}`
}

/**
 * Returns the original url for an image (for example to download it in the backend)
 *
 * @param {ImageFile} file The image object to get the url for
 *
 * @returns {string} The original url for the image
 */
export function getOriginalImageUrl(file) {
  if (typeof file !== 'object') return ''

  const { containerId, name } = file || {}

  if (!containerId || !name) return '' // If the file doesn't have a containerId or name, return an empty string
  return `${import.meta.env.VITE_ZAFIR_IMAGES_ORIGIN}/${containerId}/${name}`
}
