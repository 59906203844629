import PropTypes from 'prop-types'

export default function ConditionalWrap({
  children,
  condition,
  ifRender,
  elseRender,
}) {
  if (condition && typeof ifRender === 'function') {
    return ifRender(children)
  }

  if (typeof elseRender === 'function') {
    return elseRender(children)
  }

  return children
}
ConditionalWrap.propTypes = {
  children: PropTypes.node,
  condition: PropTypes.bool,
  elseRender: PropTypes.func,
  ifRender: PropTypes.func,
}
