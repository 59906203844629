import { useEffect } from 'react'

import useCurrentEntity from '@modules/entities/services/hooks/useCurrentEntity'
import { getImageUrl } from '@utils/images'

/**
 * Sets the favicon for the current entity.
 * This hook should be used in the main router component.
 * @returns {void}
 */
export default function useFavicon() {
  const faviconUrl = useFaviconUrl()

  useEffect(() => {
    const favicon = document.querySelector('link[rel="icon"]')

    // Change the favicon
    favicon.href = faviconUrl
  }, [faviconUrl])
}

/**
 * Get the favicon URL for the current entity.
 * @returns {string} The favicon URL.
 */
export function useFaviconUrl() {
  const { entity } = useCurrentEntity({ includeNetwork: true })

  // We apply the favicon depending on the network.
  const faviconUrl = entity?.network?.favicon

  return getImageUrl(faviconUrl) || '/favicon.svg'
}
