import React from 'react'

import { parseUserAgent } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import Dialog, { DialogContent } from './FloatingDialog'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

// Get browser name and OS from user agent
const { browser, os } = parseUserAgent(window.navigator.userAgent)
const browserName = browser.name

// Define unsupported browsers
const unsupportedBrowsers = {
  // Firefox: {
  //   version: 'any',
  //   message: 'unsupportedBrowserMessageFirefox',
  // },
  Ie: {
    version: 'any',
    message: 'unsupportedBrowserMessageIE',
  },
}

const unsupportedBrowser = unsupportedBrowsers[browserName]

// Define recommended browsers
const recommendedBrowsers = {
  Chrome: {
    version: 'any',
    downloadUrl: 'https://www.google.com/chrome',
  },
  Edge: {
    version: 'any',
    downloadUrl: 'https://www.microsoft.com/edge',
  },
  Firefox: {
    version: 'any',
    downloadUrl: 'https://www.mozilla.org/firefox',
  },
  Brave: {
    version: 'any',
    downloadUrl: 'https://brave.com',
  },
  Safari: {
    version: 'any',
    disabled: os.name !== 'Mac OS',
    downloadUrl: 'https://www.apple.com/safari',
  },
}

export default function UnsupportedBrowserAlert() {
  const { t } = useTranslation()

  if (!unsupportedBrowser) return null

  const { message, version } = unsupportedBrowser

  return (
    <Dialog initialOpen>
      <DialogContent
        title={t('unsupportedBrowser')}
        boxClass="max-w-sm"
        childrenClass="flex flex-col gap-4"
      >
        {t('unsupportedBrowserMessage', {
          browserName,
          version,
        })}
        {message && <p>{t(message)}</p>}

        <p>{t('recommendedBrowsers')}:</p>
        <ul className="border-t pt-4 list-disc pl-4">
          {Object.entries(recommendedBrowsers).map(
            ([browserName, { downloadUrl, disabled }]) => {
              if (disabled) return null

              return (
                <li
                  key={browserName}
                  className=" hover:bg-primary-50 p-1 px-2 rounded-lg"
                >
                  <a
                    href={downloadUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex justify-between items-center gap-2"
                  >
                    <span className="font-semibold">{browserName}</span>
                    <span className="text-primary-500 inline-flex gap-2 items-center text-sm">
                      <Icon name="link" />
                    </span>
                  </a>
                </li>
              )
            }
          )}
        </ul>
      </DialogContent>
    </Dialog>
  )
}
