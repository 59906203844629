import isNil from 'lodash/isNil'

export { isNil }

/**
 * Checks if a value is set (not `null` nor `undefined`)
 * @param {any} value the value to Check
 * @returns {boolean} true if the value is
 *
 * @example
 * isSet('') // true
 * isSet(0) // true
 * isSet([]) // true
 * isSet({}) // true
 * isSet(null) // false
 * isSet(undefined) // false
 */
export function isSet(value) {
  return value !== undefined && value !== null
}

/**
 * Checks if provided `value` is of given `type`
 *
 * @param {any} value the value to check
 * @param {String} type Supported types: `array`, `boolean`, `function`, `object`, `string`, and `undefined`
 * @returns boolean
 *
 * @example
 * isOfType([], 'array') // true
 * isOfType({}, 'object') // true
 * isOfType(() => {}, 'function') // true
 * isOfType('', 'string') // true
 * isOfType(1, 'number') // true
 * isOfType(null, 'object') // false
 * isOfType(undefined, 'undefined') // false
 */
export function isOfType(value, type) {
  return type === 'array'
    ? Array.isArray(value)
    : isSet(value)
      ? typeof value === type
      : false
}

/**
 * Check if value is an object
 * @param {any} value the value to check
 * @returns {boolean} true if the value is an object
 *
 * @example
 * isObject({}) // true
 * isObject([]) // false
 * isObject('') // false
 * isObject(1) // false
 * isObject(() => {}) // false
 */
export function isObject(value) {
  return isOfType(value, 'object')
}

/**
 * Check if value is a function
 * @param {any} value the value to check
 * @returns {boolean} true if the value is a function
 *
 * @example
 * isFunction(() => {}) // true
 * isFunction({}) // false
 * isFunction([]) // false
 * isFunction('') // false
 * isFunction(1) // false
 */
export function isFunction(value) {
  return isOfType(value, 'function')
}

/**
 * Check if value is a string
 * @param {any} value the value to check
 * @returns {boolean} true if the value is a string
 * @example
 * isString('') // true
 * isString(1) // false
 * isString([]) // false
 * isString({}) // false
 * isString(() => {}) // false
 */
export function isString(value) {
  return isOfType(value, 'string')
}

/**
 * Check if value is a number
 * @param {any} value the value to check
 * @returns {boolean} true if the value is a number
 * @example
 * isNumber(1) // true
 * isNumber('') // false
 * isNumber([]) // false
 * isNumber({}) // false
 * isNumber(() => {}) // false
 */
export function isNumber(value) {
  return isOfType(value, 'number')
}

/**
 * Check if value is an array
 * @param {any} value the value to check
 * @returns {boolean} true if the value is an array
 * @example
 * isArray([]) // true
 * isArray('') // false
 * isArray(1) // false
 * isArray({}) // false
 * isArray(() => {}) // false
 */
export function isArray(value) {
  return isOfType(value, 'array')
}
