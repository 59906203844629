import { library } from '@fortawesome/fontawesome-svg-core'
// Adds basic icon styles (like .svg-inline--fa)
import '@fortawesome/fontawesome-svg-core/styles.css'
import {
  faAppStore,
  faFacebook,
  faGoogle,
  faGooglePlay,
  faInstagram,
  faLine,
  faPaypal,
  faPinterest,
  faStripe,
  faTelegram,
  faTiktok,
  faTwitter,
  faVimeo,
  faWhatsapp,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import {
  faAngleDown,
  faAngleUp,
  faArrowDownFromLine,
  faArrowLeft,
  faArrowLeftFromLine,
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowRightFromLine,
  faArrowUp,
  faArrowUpFromLine,
  faArrowsH,
  faArrowsRepeat,
  faArrowsV,
  faAsterisk,
  faAt,
  faBaby,
  faBadgeCheck,
  faBan,
  faBars,
  faBell,
  faBible,
  faBlock,
  faBold,
  faBook,
  faBookmark,
  faBooks,
  faBoot,
  faBorderBottom,
  faBorderBottomRight,
  faBorderLeft,
  faBorderOuter,
  faBorderRight,
  faBorderTop,
  faBorderTopLeft,
  faBoxArchive,
  faBrackets,
  faBracketsCurly,
  faBrazilianRealSign,
  faBug,
  faCalendar,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarClock,
  faCalendarDay,
  faCalendarEdit,
  faCalendarXmark,
  faCamcorder,
  faCampfire,
  faChartBar,
  faChartMixed,
  faChartNetwork,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronSquareDown,
  faChevronUp,
  faChevronsLeft,
  faChevronsRight,
  faChurch,
  faCircle,
  faCircleDot,
  faClapperboardPlay,
  faClipboard,
  faClipboardCheck,
  faClipboardList,
  faClipboardUser,
  faClock,
  faClone,
  faCloudDownload,
  faCode,
  faCodeSimple,
  faCog,
  faCoins,
  faColumns,
  faCommentAlt,
  faCommentEdit,
  faComments,
  faCopy,
  faCreditCardFront,
  faDesktop,
  faDiagramProject,
  faDirections,
  faDollarSign,
  faDonate,
  faDownload,
  faEarthAmericas,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpen,
  faEuroSign,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkSquare,
  faEye,
  faEyeSlash,
  faFaceAwesome,
  faFile,
  faFileAlt,
  faFileCertificate,
  faFileCheck,
  faFileExport,
  faFileImport,
  faFileInvoice,
  faFilePlus,
  faFileSearch,
  faFileSlash,
  faFileUpload,
  faFill,
  faFillDrip,
  faFilter,
  faFlag,
  faFlagPennant,
  faFlatbread,
  faFolder,
  faFolderUser,
  faFolders,
  faFont,
  faFontCase,
  faGlobe,
  faGraduationCap,
  faGripLinesVertical,
  faH1,
  faH2,
  faH3,
  faH4,
  faHandPointer,
  faHashtag,
  faHeading,
  faHeadphones,
  faHeart,
  faHome,
  faHorizontalRule,
  faHyphen,
  faImage,
  faImagePolaroid,
  faImageSlash,
  faImages,
  faInbox,
  faInboxFull,
  faIndent,
  faIndianRupeeSign,
  faInfoCircle,
  faInputPipe,
  faInputText,
  faItalic,
  faKey,
  faLanguage,
  faLaptop,
  faLaptopMobile,
  faLayerGroup,
  faLink,
  faList,
  faListOl,
  faListTree,
  faListUl,
  faLocation,
  faLock,
  faLockOpen,
  faMagic,
  faMagnifyingGlassArrowsRotate,
  faMap,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMerge,
  faMessageLines,
  faMessageQuestion,
  faMessages,
  faMicrophone,
  faMinus,
  faMobile,
  faNetworkWired,
  faNewspaper,
  faObjectsColumn,
  faOutdent,
  faPalette,
  faPaperPlane,
  faParagraph,
  faParkingCircle,
  faPaste,
  faPenField,
  faPencilPaintbrush,
  faPeopleDress,
  faPeopleGroup,
  faPeriod,
  faPerson,
  faPersonChalkboard,
  faPhone,
  faPhotoFilmMusic,
  faPlay,
  faPlayCircle,
  faPlug,
  faPlugCircleBolt,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faQuoteLeft,
  faRandom,
  faRectangleLandscape,
  faRectangleWide,
  faRedo,
  faRobot,
  faRoute,
  faRouter,
  faRss,
  faSave,
  faScrewdriverWrench,
  faSearch,
  faShapes,
  faShare,
  faShareSquare,
  faShieldAlt,
  faShoppingBasket,
  faSignIn,
  faSignOut,
  faSitemap,
  faSlidersH,
  faSort,
  faSparkles,
  faSpinner,
  faSpinnerThird,
  faSquare,
  faSquareDashed,
  faSquareRight,
  faStamp,
  faStar,
  faSterlingSign,
  faStream,
  faStrikethrough,
  faSubscript,
  faSuperscript,
  faSwap,
  faSyncAlt,
  faTableLayout,
  faTablet,
  faTabletAlt,
  faTachometerAlt,
  faTag,
  faTags,
  faText,
  faTextSize,
  faTh,
  faThList,
  faTicket,
  faTimes,
  faTimesCircle,
  faTimesSquare,
  faTrash,
  faTrashAlt,
  faTrees,
  faTruck,
  faTv,
  faTvRetro,
  faUndo,
  faUniversity,
  faUnlink,
  faUser,
  faUserGroup,
  faUserPlus,
  faUserShield,
  faUserTag,
  faUserUnlock,
  faUsers,
  faUsersBetweenLines,
  faUsersClass,
  faVideo,
  faVideoSlash,
  faWater,
  faWheat,
  faWheelchair,
  faWifi,
  faWrench,
  faYenSign,
} from '@fortawesome/pro-regular-svg-icons'
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons'

/*
 * IMPORTANT: when you add/remove an icon to/from the library, please make sure its story is also added/removed.
 * NOTE: please, try to keep the alphabetical order in this list (and the import) to making the search of existing icons easier.
 */

const iconsList = [
  faAngleDown,
  faAngleUp,
  faAppStore,
  faArrowDownFromLine,
  faArrowLeft,
  faArrowLeftFromLine,
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowRightFromLine,
  faArrowsH,
  faArrowsRepeat,
  faArrowsV,
  faArrowUp,
  faArrowUpFromLine,
  faAsterisk,
  faAt,
  faBaby,
  faBadgeCheck,
  faBan,
  faBars,
  faBell,
  faBible,
  faBlock,
  faBold,
  faBook,
  faBookmark,
  faBooks,
  faBoot,
  faBorderBottom,
  faBorderBottomRight,
  faBorderLeft,
  faBorderOuter,
  faBoxArchive,
  faBorderRight,
  faBorderTop,
  faBorderTopLeft,
  faBrackets,
  faBracketsCurly,
  faBrazilianRealSign,
  faBug,
  faCalendar,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarClock,
  faCalendarDay,
  faCalendarEdit,
  faCalendarXmark,
  faCamcorder,
  faCampfire,
  faChartBar,
  faMicrophone,
  faChartMixed,
  faChartNetwork,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronsLeft,
  faChevronsRight,
  faChevronSquareDown,
  faChevronUp,
  faChurch,
  faCircle,
  faCircleDot,
  faClapperboardPlay,
  faClipboard,
  faClipboardList,
  faClipboardCheck,
  faClipboardUser,
  faClock,
  faClone,
  faCloudDownload,
  faCode,
  faCodeSimple,
  faCog,
  faCoins,
  faColumns,
  faCommentAlt,
  faCommentEdit,
  faComments,
  faCopy,
  faCreditCardFront,
  faDesktop,
  faDiagramProject,
  faDirections,
  faDollarSign,
  faDonate,
  faEarthAmericas,
  faDownload,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpen,
  faEuroSign,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkSquare,
  faEye,
  faEyeSlash,
  faFaceAwesome,
  faFacebook,
  faFile,
  faFileAlt,
  faFileCertificate,
  faFileCheck,
  faFileExport,
  faFileImport,
  faFileInvoice,
  faFilePlus,
  faFileSearch,
  faFileSlash,
  faFileUpload,
  faFill,
  faFillDrip,
  faFilter,
  faFlag,
  faFlagPennant,
  faFlatbread,
  faFolderUser,
  faFolders,
  faFolder,
  faFont,
  faFontCase,
  faGlobe,
  faGoogle,
  faGooglePlay,
  faGraduationCap,
  faGripLinesVertical,
  faH1,
  faH2,
  faH3,
  faH4,
  faHandPointer,
  faHashtag,
  faHeading,
  faHeadphones,
  faHeart,
  faHome,
  faHorizontalRule,
  faHyphen,
  faImage,
  faImagePolaroid,
  faImageSlash,
  faImages,
  faInbox,
  faInboxFull,
  faIndent,
  faIndianRupeeSign,
  faInfoCircle,
  faInputPipe,
  faInputText,
  faInstagram,
  faItalic,
  faKey,
  faLanguage,
  faLaptop,
  faLaptopMobile,
  faLayerGroup,
  faLine,
  faLink,
  faList,
  faListOl,
  faListTree,
  faListUl,
  faLocation,
  faLock,
  faLockOpen,
  faMagic,
  faMagnifyingGlassArrowsRotate,
  faMap,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMerge,
  faMessageQuestion,
  faMessages,
  faMessageLines,
  faMinus,
  faMobile,
  faNetworkWired,
  faNewspaper,
  faOutdent,
  faObjectsColumn,
  faPalette,
  faPaperPlane,
  faParagraph,
  faParkingCircle,
  faPaste,
  faPaypal,
  faPencilPaintbrush,
  faPenField,
  faPeopleDress,
  faPeopleGroup,
  faPerson,
  faPeriod,
  faPersonChalkboard,
  faPinterest,
  faPlay,
  faPlayCircle,
  faPlug,
  faPlugCircleBolt,
  faPlus,
  faPlusCircle,
  faPhotoFilmMusic,
  faPhone,
  faQuestionCircle,
  faQuoteLeft,
  faRandom,
  faRectangleLandscape,
  faRectangleWide,
  faRedo,
  faRobot,
  faRoute,
  faRouter,
  faRss,
  faSave,
  faScrewdriverWrench,
  faSearch,
  faShapes,
  faShare,
  faShareSquare,
  faShieldAlt,
  faShoppingBasket,
  faSignIn,
  faSignOut,
  faSitemap,
  faSlidersH,
  faSort,
  faSparkles,
  faSpinner,
  faSpinnerThird,
  faSquare,
  faSquareDashed,
  faSquareRight,
  faStamp,
  faStar,
  faStarSolid,
  faSterlingSign,
  faStream,
  faStrikethrough,
  faStripe,
  faSubscript,
  faSuperscript,
  faSwap,
  faSyncAlt,
  faTableLayout,
  faTabletAlt,
  faTablet,
  faTachometerAlt,
  faTag,
  faTags,
  faTelegram,
  faText,
  faTextSize,
  faTh,
  faThList,
  faTicket,
  faTiktok,
  faTimes,
  faTimesCircle,
  faTimesSquare,
  faTrash,
  faTrashAlt,
  faTrees,
  faTruck,
  faTv,
  faTvRetro,
  faTwitter,
  faUndo,
  faUniversity,
  faUnlink,
  faUser,
  faUserGroup,
  faUserPlus,
  faUsers,
  faUsersBetweenLines,
  faUsersClass,
  faUserShield,
  faUserTag,
  faUserUnlock,
  faVideo,
  faVideoSlash,
  faVimeo,
  faWater,
  faWheat,
  faWhatsapp,
  faWheelchair,
  faWifi,
  faWrench,
  faYenSign,
  faYoutube,
]

export const icons = iconsList.filter((icon, index) => {
  const sameIndex = iconsList.indexOf(icon) === index

  if (!sameIndex) {
    // eslint-disable-next-line no-console
    console.error(
      `Duplicate icon "${icon.iconName}": Check if it's not twice in the list, or if is an alias of other icon that has been already imported.`
    )
  }

  return sameIndex
})

export function getIconNames() {
  return icons.map(({ iconName, prefix }) => ({ iconName, prefix }))
}

// Adds selected icons to library
library.add(...icons)
