import { useMemo } from 'react'

/**
 * The button variants
 *
 * @typedef {'ai' | 'basic' | 'primary' | 'primary-light' | 'success' | 'success-light' | 'info' | 'favorite' | 'favorite-light' | 'warn' | 'warn-light' | 'danger' | 'danger-light' | 'flat' | 'flat-primary' | 'flat-success' | 'flat-warn' | 'flat-favorite' | 'flat-danger' | 'flat-info' | 'flat-light' | 'light'} ButtonVariant
 */
export const variants = {
  'ai': 'bg-ai-50 text-ai-600 border-ai-600 focus:ring-ai-600/50',
  'basic': 'bg-gray-50 text-gray-600 border-gray-300 focus:ring-gray-300/80',
  'primary':
    'bg-primary-500 text-primary-50 border-primary-600 focus:ring-primary-600/50',
  'primary-light': 'bg-primary-50 text-primary-600 border-primary-600',
  'success':
    'bg-success-500 text-white border-success-600 focus:ring-success-600/50',
  'success-light':
    'bg-success-50 text-success-600 border-success-600 focus:ring-success-600/50',
  'info': 'bg-info-100 text-info-600 border-info-300 focus:ring-info-300/50',
  'favorite': 'bg-favorite-500 text-white border-favorite-600',
  'favorite-light': 'bg-favorite-50 text-favorite-600 border-favorite-600',
  'warn': 'bg-warn-500 text-white border-warn-600 focus:ring-warn-600/50',
  'warn-light':
    'bg-warn-50 text-warn-600 border-warn-600 focus:ring-warn-600/50',
  'danger':
    'bg-danger-500 text-white border-danger-600 focus:ring-danger-600/50',
  'danger-light':
    'bg-danger-50 text-danger-600 border-danger-600 focus:ring-danger-600/50',
  'flat': 'text-gray-600 hover:text-primary-600',
  'flat-primary': 'text-primary-600 hover:text-primary-700',
  'flat-success': 'text-success-600 hover:text-success-700',
  'flat-warn': 'text-warn-600 hover:text-warn-700',
  'flat-favorite': 'text-favorite-500 hover:text-favorite-600',
  'flat-danger': 'text-danger-600 hover:text-danger-700',
  'flat-info': 'text-info-600 hover:text-info-700',
  'flat-light': 'text-primary-50 hover:text-primary-100 ',
  'light':
    'border-primary-200 text-primary-100 hover:text-primary-50 bg-transparent focus:ring-primary-100/50',
}

/**
 * @typedef {'inherit' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'} ButtonSize A button size
 */

/**
 * The button sizes
 * @type {Record<ButtonSize, string>}
 */
const sizesStyles = {
  inherit: 'gap-1', // Only a small gap to the inherit size
  xs: 'h-6 py-0.5 px-2 gap-2 text-xs',
  sm: 'h-8 py-1 px-3 gap-2 text-sm',
  md: 'h-10 py-2 px-4 gap-3 text-base',
  lg: 'h-12 py-3 px-5 gap-4 text-lg',
  xl: 'h-16 py-4 px-6 gap-4 text-xl',
}

/**
 * The button rounded styles
 * @type {Record<ButtonSize, string>}
 */
const roundedStyles = {
  inherit: '',
  xs: 'rounded',
  sm: 'rounded',
  md: 'rounded-md',
  lg: 'rounded-lg',
  xl: 'rounded-xl',
}

/**
 * Get the classes for the button component
 * @param {Object} props The button classes props
 * @param {ButtonVariant} props.variant The button variant
 * @param {ButtonSize} props.size The button size
 * @param {Boolean} props.disabled Whether the button is disabled
 * @param {Boolean} props.pressed Whether the button is pressed
 * @param {Boolean} props.rounded Whether the button is rounded
 * @returns {string} The classes string
 */
export function useButtonClasses({
  variant,
  size,
  disabled,
  pressed,
  rounded = true,
}) {
  // Get the variant classes
  const { variantClass, isFlat, isLight } = useButtonVariant(variant)

  return useMemo(() => {
    // Get the size classes based on the size prop
    const sizeClass = sizesStyles[size] ?? sizesStyles.md

    // Get the rounded classes based on both rounded and size props
    const roundedClass = rounded ? roundedStyles[size] || roundedStyles.md : ''

    // Add disabled class if the button is disabled
    const disabledClass = disabled
      ? 'cursor-not-allowed opacity-50'
      : 'cursor-pointer'

    // Add pressed class if the button is pressed
    const pressedClass = pressed ? 'ring-1 ring-primary-500' : ''

    // Don't add shadow classes if the button is flat, light or disabled
    const shadowClass =
      disabled || isFlat || isLight ? '' : 'shadow hover:shadow-md'

    // Don't add border classes if the button is flat
    const buttonClass = isFlat
      ? ''
      : 'border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white'

    // Return the classes string
    return `flex items-center ${sizeClass} ${roundedClass} ${variantClass} ${disabledClass} ${pressedClass} ${shadowClass} ${buttonClass}`
  }, [size, disabled, pressed, rounded, variantClass, isFlat, isLight])
}

/**
 * Get the variant classes for the Button component
 * @param {ButtonVariant} variant The button variant
 * @returns {{isFlat: Boolean, isLight: Boolean, variantClass: String}} The variant classes
 */
export function useButtonVariant(variant) {
  return useMemo(() => {
    const variantClass = variants[variant] || variants.basic

    // Check if the variant includes 'flat'
    const isFlat = variant?.includes('flat')

    // Check if the variant includes 'light'
    const isLight = variant === 'light'

    // Return the variant classes, isFlat and isLight
    return {
      isFlat,
      isLight,
      variantClass,
    }
  }, [variant])
}
