import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import ChatwootContext from './chatwootContext'

export default function ChatwootProvider({ children }) {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    const readyEvent = window.addEventListener('chatwoot:ready', function () {
      setReady(true)
    })

    return () => window.removeEventListener('chatwoot:ready', readyEvent)
  }, [])

  return (
    <ChatwootContext.Provider value={ready}>
      {children}
    </ChatwootContext.Provider>
  )
}
ChatwootProvider.propTypes = {
  children: PropTypes.node,
}
