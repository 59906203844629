import axios from 'axios'
import set from 'lodash/set'

// Default configuration for axios
axios.defaults.headers.post['Content-Type'] = 'application/json'

const entityCookie = document.cookie
  .split(';')
  .find(c => c.trim().startsWith('entityId'))
  ?.split('=')[1]

if (entityCookie && entityCookie !== 'null')
  axios.defaults.headers.common['entityId'] = entityCookie

/**
 * Set the entityId in the headers of axios requests to fetch entity
 * @param {*} entityId - The entity id
 * @returns `void`
 */
export function setEntityHeaders(entityId) {
  if (entityId) {
    axios.defaults.headers.common['entityId'] = entityId
  } else {
    delete axios.defaults.headers.common['entityId']
  }
}

/**
 * Default fetch for "get" methods
 * @param {String} url The url to fetch
 * @param {Object} params The query params
 * @returns {Promise} The response data
 */
export async function getFetch(url, params = {}) {
  if (!url) return

  try {
    return await axios.get(url, { params }).then(res => res.data)
  } catch (error) {
    throw error.response
  }
}

/**
 * Default fetch for "post" methods
 * @param {String} url The url to fetch
 * @param {Object} data The data to send
 * @param {Object} config The request configuration
 * @returns {Promise} The response data
 */
export async function postFetch(url, data = {}, config = {}) {
  if (!url) return

  try {
    return await axios
      .post(url, data, setRequestConfig(config))
      .then(res => res.data)
  } catch (error) {
    throw error.response
  }
}

/**
 * Default fetch for "put" methods
 * @param {String} url The url to fetch
 * @param {Object} data The data to send
 * @param {Object} config The request configuration
 * @returns {Promise} The response data
 */
export async function putFetch(url, data = {}, config = {}) {
  if (!url) return

  try {
    return await axios
      .put(url, data, setRequestConfig(config))
      .then(res => res.data)
  } catch (error) {
    throw error.response
  }
}

/**
 * Default fetch for "patch" methods
 * @param {String} url The url to fetch
 * @param {Object} data The data to send
 * @param {Object} config The request configuration
 * @returns {Promise} The response data
 */
export async function patchFetch(url, data = {}, config = {}) {
  if (!url) return

  try {
    return await axios
      .patch(url, data, setRequestConfig(config))
      .then(res => res.data)
  } catch (error) {
    throw error.response
  }
}

/**
 * Default fetch uploading files
 * @param {String} url The url to fetch
 * @param {Object} data The data to send
 * @param {Object} options The request configuration
 * @returns {Promise} The response data
 */
export async function fileUpload(
  url,
  data = {},
  options = {
    onProgress: undefined,
    onStart: undefined,
    onComplete: undefined,
    multiple: false,
    folder: '',
    privateUpload: false,
  }
) {
  if (!url) return

  const config = {}

  const formData = new FormData()

  if (options?.multiple) {
    for (let i = 0; i < data.length; i++) {
      formData.append('files', data[i])
    }
  } else {
    formData.append('file', data)
  }

  // Add option to the formData if it's true
  if (options?.folder === 'zafir') {
    formData.append('folder', 'zafir')
  }

  // Add the font option to the formData if it's true
  if (options?.folder === 'fonts') {
    formData.append('folder', 'fonts')
  }

  // Add the privateUpload option to the formData if it's true
  if (options?.privateUpload === true) {
    formData.append('privateUpload', true)
  }

  if (typeof options?.onProgress === 'function') {
    config.onUploadProgress = ({ loaded, total }) =>
      options.onProgress(Math.round((loaded * 100) / total), loaded, total)
  }

  try {
    if (typeof options?.onStart === 'function') {
      options.onStart()
    }

    return await axios
      .post(url, formData, {
        ...config,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(res => {
        if (typeof options?.onComplete === 'function') {
          options.onComplete(res.data)
        }
        return res.data
      })
  } catch (error) {
    throw error.response
  }
}

/**
 * Default fetch for "patch" methods
 * @param {String} url The url to fetch
 * @param {Object} config The request configuration
 * @returns {Promise} The response data
 */
export async function deleteFetch(url, config = {}) {
  if (!url) return

  try {
    return await axios
      .delete(url, setRequestConfig(config))
      .then(res => res.data)
  } catch (error) {
    throw error.response
  }
}

function setRequestConfig(config = {}) {
  if (config?.fileUpload === true) {
    // Set the headers to multipart/form-data
    config.headers = set(
      config.headers ?? {},
      'Content-Type',
      'multipart/form-data'
    )

    // Delete the fileUpload key from the config object
    delete config.fileUpload
  }

  return config
}
