import { useMutation, useQueryClient } from 'react-query'

import { deleteFetch, patchFetch, postFetch } from '@utils/fetcher'

export const entitiesApiUrl = '/api/entities'
export const publicEntitiesApiUrl = '/api/entities/public'
export const currentEntityApiUrl = `${entitiesApiUrl}/current`

export function useUpdateCurrentEntity() {
  const queryClient = useQueryClient()

  return useMutation(data => patchFetch(currentEntityApiUrl, data), {
    onMutate: data => {
      // Update entity optimistically
      queryClient.setQueryData(['entities', 'current'], data)
    },
    onSuccess: data => {
      // Update entity data with API response
      queryClient.setQueryData(['entities', 'current'], data)
    },
    onSettled: () => {
      queryClient.invalidateQueries('entities')
    },
  })
}

export function useCreateOrUpdateEntity(entity) {
  const queryClient = useQueryClient()

  return useMutation(
    data => {
      entity?.id
        ? patchFetch(`${entitiesApiUrl}/${entity.id}`, data)
        : postFetch(entitiesApiUrl, data)
    },
    {
      onMutate: data => {
        // Update entity optimistically
        queryClient.setQueryData(
          ['entities', entity ? entity.id : 'new-entity'],
          data
        )
      },
      onSuccess: data => {
        // Update entity data with API response
        queryClient.setQueryData(
          ['entities', entity ? entity.id : data.id],
          data
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries('entities')
      },
    }
  )
}

export function useCreateSubentity(entity) {
  const queryClient = useQueryClient()
  const url = entity ? `${entitiesApiUrl}/${entity.id}/subentities` : null

  return useMutation(data => postFetch(url, data), {
    onSuccess: data => {
      queryClient.setQueryData(['entities', entity?.id], data)
      queryClient.invalidateQueries('entities')
    },
  })
}

export function useUpdateEntity(entity) {
  const queryClient = useQueryClient()
  const url = entity ? `${entitiesApiUrl}/${entity.id}` : null

  return useMutation(data => patchFetch(url, data), {
    onMutate: data => {
      // Update entity optimistically
      queryClient.setQueryData(['entities', entity.id], { ...entity, ...data })
    },
    onSuccess: data => {
      // Update entity data with API response
      queryClient.setQueryData(['entities', entity.id], data)
    },
    onSettled: () => {
      queryClient.invalidateQueries('entities')
    },
  })
}

export function useCreateEntityContactPerson(entity) {
  const queryClient = useQueryClient()
  const url = entity ? `${entitiesApiUrl}/${entity.id}/contactPersons` : null

  return useMutation(
    data => {
      return postFetch(url, data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('entities')
      },
    }
  )
}

export function useDisableEntity(entity) {
  const queryClient = useQueryClient()
  const url = entity ? `${entitiesApiUrl}/${entity?.id}/disable` : null

  return useMutation(() => patchFetch(url), {
    onSuccess: () => {
      queryClient.invalidateQueries('entities')
    },
  })
}

export function useEnableEntity(entity) {
  const queryClient = useQueryClient()
  const url = entity ? `${entitiesApiUrl}/${entity?.id}/enable` : null

  return useMutation(() => patchFetch(url), {
    onSuccess: () => {
      queryClient.invalidateQueries('entities')
    },
  })
}

export function useToggleEntity(entity) {
  const queryClient = useQueryClient()
  const url = entity
    ? `${entitiesApiUrl}/${entity.id}/${entity.enabled ? 'disable' : 'enable'}`
    : null

  return useMutation(() => patchFetch(url), {
    onSuccess: () => {
      queryClient.invalidateQueries('entities')
    },
  })
}

export function useDeleteEntity(entity) {
  const queryClient = useQueryClient()
  const url = entity ? `${entitiesApiUrl}/${entity.id}` : null

  return useMutation(() => deleteFetch(url), {
    onSuccess: () => {
      queryClient.invalidateQueries('entities')
    },
  })
}

export function useCreateEntity() {
  const queryClient = useQueryClient()

  return useMutation(entity => postFetch(entitiesApiUrl, entity), {
    onSuccess: data => {
      queryClient.setQueryData(['entities', data.id], data)
      queryClient.invalidateQueries('entities')
    },
  })
}

export function useRestoreEntity(entity) {
  const queryClient = useQueryClient()
  const url = entity ? `${entitiesApiUrl}/${entity.id}/restore` : null

  return useMutation(() => patchFetch(url), {
    onSuccess: () => {
      queryClient.invalidateQueries('entities')
    },
  })
}

export default {
  useUpdateCurrentEntity,
  useCreateOrUpdateEntity,
  useCreateSubentity,
  useUpdateEntity,
  useCreateEntityContactPerson,
  useDisableEntity,
  useEnableEntity,
  useToggleEntity,
  useDeleteEntity,
  useCreateEntity,
  useRestoreEntity,
}
