import PropTypes from 'prop-types'
import React from 'react'

const typeImageMap = {
  error: {
    image: '/images/error.svg',
    alt: 'A lady fixing a bug',
  },
  maintenance: {
    image: '/images/maintenance.svg',
    alt: 'Some guys trying to plug a huge iMac',
  },
  cookiesBlocked: {
    image: '/images/cookies.svg',
    alt: 'A lady eating cookies from a jar',
  },
}

export default function ErrorScreen({
  backLabel = 'Go back',
  kicker,
  message = 'An error occurred, please try again later.',
  refreshLabel = 'Refresh',
  url = '/',
  title = 'Something went wrong',
  showBack,
  type = 'error',
}) {
  const { image } = typeImageMap[type] || typeImageMap.error

  return (
    <div className="flex items-center justify-center bg-white">
      <div className="flex h-screen items-center justify-between p-8 md:max-w-4xl md:flex-row md:space-x-4 lg:max-w-6xl">
        <div className="flex max-w-sm items-center justify-center p-4 md:max-w-lg lg:px-8">
          <img src={image} alt="Error lady and bug" className="max-h-72" />
        </div>
        <div className="flex grow flex-col gap-4 p-8 md:justify-center">
          <div className="space-y-2">
            {kicker && (
              <h3 className="text-xl font-semibold text-danger-600">
                {kicker}
              </h3>
            )}
            <h1 className="text-4xl font-semibold text-primary-700">{title}</h1>
          </div>
          <p className="text-lg text-gray-600">{message}</p>
          {(refreshLabel || showBack) && (
            <div className="flex gap-4">
              {refreshLabel && (
                <a
                  href="#reload"
                  className="text-primary-600 hover:text-primary-500 hover:underline"
                  onClick={() => window.location.reload()}
                >
                  {refreshLabel}
                </a>
              )}

              {showBack && (
                <a
                  className="text-primary-600 hover:text-primary-500 hover:underline"
                  href={url}
                >
                  {backLabel}
                </a>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
ErrorScreen.propTypes = {
  backLabel: PropTypes.string,
  kicker: PropTypes.string,
  message: PropTypes.string,
  refreshLabel: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(['error', 'maintenance', 'cookiesBlocked']),
  url: PropTypes.string,
  showBack: PropTypes.bool,
}
