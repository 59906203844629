import React from 'react'

import Clickable from '@ui/helpers/Clickable'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

/**
 * @typedef {'default'|'solid'|'dark'} CloseButtonVariant The variant of the close button
 *
 * CloseButton variants
 * @type {Object.<CloseButtonVariant, string>} CloseButtonVariant
 */
const buttonVariantStyles = {
  default: 'text-gray-700 hover:text-gray-900 hover:bg-white/50',
  solid:
    'text-gray-600 bg-gray-200 shadow drop-shadow-sm hover:shadow-md hover:drop-shadow-lg border border-gray-300 hover:border-gray-400',
  dark: 'text-white bg-gray-500 hover:bg-gray-600',
}

/**
 * Close button component
 * @param {Object} props The props of the component
 * @param {Function} props.onClick The click handler
 * @param {CloseButtonVariant} [props.variant='default'] The variant of the close button
 * @returns {React.ReactElement} The close button component
 */
export default function CloseButton({ onClick, variant }) {
  const variantClasses =
    buttonVariantStyles[variant] || buttonVariantStyles.default

  return (
    <Clickable
      className={`flex h-8 w-8 shrink-0 cursor-pointer items-center justify-center rounded-full text-xl outline-none transition-all duration-300 ease-in-out focus:ring-2 ${variantClasses}`}
      onClick={onClick}
    >
      <Icon name="times" />
    </Clickable>
  )
}
