import React from 'react'

import useClickable from './useClickable'

/**
 * A clickable element with some basic a11y.
 * @param {Object} props The component props.
 * @param {'div'|'span'|'td'|'th'} [props.as='div'] The component tag name to render.
 * @param {React.ReactNode} props.children The component children.
 * @param {String} [props.className=''] The component extra classes.
 * @param {String} [props.clickableClass=''] The component extra classes only when is clickable (not disabled and has onClick/onDoubleClick).
 * @param {Boolean} props.disabled Defines if the click should be disabled.
 * @param {React.Ref} props.innerRef DOM reference provided to the clickable element.
 * @param {String} [props.keyCode='Enter'] Pressed key code expected to trigger the `onClick` event.
 * @param {Function} props.onClick Provided `onClick` event.
 * @param {Function} [props.onDoubleClick] Provided `onDoubleClick` event.
 * @param {'link'|'button'} [props.role='role'] The component role (default: 'link').
 * @returns {React.ReactElement} The clickable component.
 */
export default function Clickable({
  as = 'div',
  children,
  className = '',
  clickableClass = '',
  disabled,
  innerRef,
  keyCode = 'Enter',
  onClick,
  onDoubleClick,
  role = 'link',
  ...props
}) {
  const clickableProps = useClickable({
    className,
    clickableClass,
    keyCode,
    onClick,
    role,
  })

  // If is disabled or no onClick/onDoubleClick function is provided, render the element without the clickable props
  if (disabled || typeof (onDoubleClick ?? onClick) !== 'function') {
    return React.createElement(as, { ...props, className }, children)
  }

  return React.createElement(
    as,
    {
      ...props,
      ...clickableProps,
      onDoubleClick,
      ref: innerRef,
    },
    children
  )
}
