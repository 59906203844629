import React from 'react'

import {
  FloatingPortal,
  arrow,
  autoUpdate,
  offset,
  shift,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react'

/**
 * @typedef {'top' | 'right' | 'bottom' | 'left' | 'top-start' | 'top-end' | 'right-start' | 'right-end' | 'bottom-start' | 'bottom-end' | 'left-start' | 'left-end'} TooltipPlacement Tooltip placement
 */

const staticSides = {
  top: 'bottom',
  right: 'left',
  bottom: 'top',
  left: 'right',
}

const arrowStyles = {
  top: 'border-r border-b',
  right: 'border-l border-b',
  bottom: 'border-l border-t',
  left: 'border-r border-t',
}

/**
 * @typedef {'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'} TooltipHiddenFrom Hidden from breakpoint
 *
 * Hidden from breakpoint classes
 * @type {Record<TooltipHiddenFrom, string>}
 */
const hiddenFromStyles = {
  'xs': 'xs:hidden',
  'sm': 'sm:hidden',
  'md': 'md:hidden',
  'lg': 'lg:hidden',
  'xl': 'xl:hidden',
  '2xl': '2xl:hidden',
  '3xl': '3xl:hidden',
}

/**
 * @typedef {'xs' | 'sm' | 'md' | 'lg' | 'xl'} TooltipSize Tooltip size
 *
 * Tooltip size classes
 * @type {Record<TooltipSize, string>}
 */
const sizeStyles = {
  xs: 'text-xs px-2 py-1.5 rounded-md',
  sm: 'text-sm px-3 py-2 rounded-lg',
  md: 'text-base px-4 py-2.5 rounded-xl',
  lg: 'text-lg px-5 py-3 rounded-2xl',
  xl: 'text-xl px-6 py-4 rounded-3xl',
}

/**
 * @typedef {'xs' | 'sm' | 'md' | 'lg' | 'xl'} TooltipMaxWidth A Tooltip max width
 *
 * Tooltip max width classes
 * @type {Record<TooltipMaxWidth, string>}
 */
const tooltipWidthClasses = {
  xs: 'max-w-xs',
  sm: 'max-w-sm',
  md: 'max-w-md',
  lg: 'max-w-lg',
  xl: 'max-w-xl',
}

/**
 * Tooltip component
 * @param {Object} props Component props
 * @param {Number} [props.arrowOffset=0] Arrow offset
 * @param {React.ReactNode|Function} props.children The children
 * @param {String} [props.className=''] Additional classes
 * @param {React.ReactNode} props.content The content
 * @param {String} [props.contentClass=''] Additional classes for the content
 * @param {Boolean} [props.disabled=false] Whether the tooltip is disabled
 * @param {TooltipHiddenFrom} [props.hiddenFrom] Hidden from breakpoint
 * @param {Boolean} [props.hideArrow=false] Whether to hide the arrow
 * @param {TooltipMaxWidth} [props.maxWidth='md'] Tooltip max width
 * @param {TooltipPlacement} [props.placement='bottom'] Tooltip placement
 * @param {TooltipSize} [props.size] Tooltip size
 * @param {String} [props.tooltipClass=''] Additional classes for the tooltip
 * @returns {React.ReactElement} The component
 */
export default function Tooltip({
  arrowOffset = 0,
  children,
  className = '',
  content,
  contentClass = '',
  disabled,
  hiddenFrom,
  hideArrow,
  maxWidth = 'md',
  placement = 'bottom',
  size,
  tooltipClass = '',
}) {
  const arrowRef = React.useRef(null)
  const [isOpen, setIsOpen] = React.useState(false)

  // Set floating menu
  const {
    floatingStyles,
    refs,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
    context,
  } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    placement,
    middleware: [
      shift(),
      offset((hideArrow ? 4 : 12) + arrowOffset),
      arrow({ element: arrowRef }),
    ],
  })

  // Set hover
  const hover = useHover(context)

  // Get hover interactions
  const { getReferenceProps, getFloatingProps } = useInteractions([hover])

  // If disabled, just render the children
  if (disabled || !content) return <div className={className}>{children}</div>

  const placementSide = placement.split('-')[0]
  const staticSide = staticSides[placementSide]
  const arrowClass = arrowStyles[placementSide] ?? ''
  const sizeClass = sizeStyles[size] ?? sizeStyles.md
  const hiddenFromClass = hiddenFromStyles[hiddenFrom] ?? ''

  const tooltipSizeClass = tooltipWidthClasses[maxWidth] ?? ''

  return (
    <>
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        className={className}
      >
        {typeof children === 'function' ? children(isOpen) : children}
      </div>

      {isOpen && (
        <FloatingPortal>
          <div
            className={`z-max whitespace-normal border border-gray-500/50 bg-gray-700 opacity-90 shadow-lg ${hiddenFromClass} ${sizeClass} ${tooltipClass}`}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <div
              className={`font-semibold text-gray-200 ${tooltipSizeClass} ${contentClass}`}
            >
              {content}
            </div>
            {!hideArrow && (
              <div
                ref={arrowRef}
                className={`absolute z-10 h-2.5 w-2.5 rotate-45 border-gray-500/50 bg-gray-700 ${arrowClass}`}
                style={{
                  top: arrowY ?? '',
                  left: arrowX ?? '',
                  [staticSide]: '-6px',
                }}
              />
            )}
          </div>
        </FloatingPortal>
      )}
    </>
  )
}
