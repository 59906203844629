import React from 'react'

import { LocaleContext } from './hooks'

/**
 * A provider for the locale context. It provides the locale and dateTimeLocale to the context.
 * @param {Object} props Component props
 * @param {React.ReactNode} props.children Component children
 * @param {String} props.dateTimeLocale The date time locale
 * @param {String} props.locale The locale
 * @returns {React.ReactElement} The LocaleProvider component
 */
export default function LocaleProvider({ children, dateTimeLocale, locale }) {
  return (
    <LocaleContext.Provider value={{ locale, dateTimeLocale }}>
      {children}
    </LocaleContext.Provider>
  )
}
