import React from 'react'

import { useTranslation } from 'react-i18next'
import { Link as RRLink } from 'react-router-dom'

import ConditionalWrap from '@ui/helpers/ConditionalWrap'
import { isExternalUrl } from '@utils/urls'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const linkStyles = {
  basic:
    'text-primary-500 hover:text-primary-700 group-[.row-active]:text-primary-50 group-[.row-active]:hover:text-primary-100 underline underline-offset-0 hover:underline-offset-4 decoration-1 decoration-transparent hover:decoration-primary-700/50 group-[.row-active]:hover:decoration-primary-100/50 ease-in-out transition-all',
  interactive: 'cursor-pointer',
  nonInteractive: 'cursor-pointer',
}

/**
 * Link component
 * @typedef {Object} LinkProps The Link component props
 * @property {Boolean} [basic=true] Sets some basics styles for a link (like the underline and primary color)
 * @property {node} children Link's childen
 * @property {String} [className=''] Additional classes to be provided to the link
 * @property {String} href Link's target url
 * @property {Function} onClick link's event on click
 */

/**
 * Link is a component that renders a link to a page or an external url.
 *
 * @type {React.ForwardRefRenderFunction<HTMLAnchorElement, LinkProps>} Link component
 */
const Link = React.forwardRef(function Link(linkProps, ref) {
  const { basic = true, href, children, ...props } = linkProps

  const basicClass = basic ? linkStyles.basic : ''

  const interactiveClass =
    props.onClick || href ? linkStyles.interactive : linkStyles.nonInteractive

  props.className = `${basicClass} ${interactiveClass} ${props.className || ''}`

  if (!href) return <a {...props}>{children}</a>

  const external = isExternalUrl(href)

  if (external) {
    if (!props.target) {
      props.target = '_blank'
    }
    props.rel = 'noreferrer'
  }

  return external ? (
    <a href={href} {...props} ref={ref}>
      {children}
    </a>
  ) : (
    <RRLink to={href} {...props} ref={ref}>
      {children}
    </RRLink>
  )
})
Link.displayName = 'Link'

/**
 * Link component
 * @param {React.ForwardRefRenderFunction<HTMLButtonElement, ButtonProps>} props Component props
 * @returns {React.ReactElement}
 *
 * @example
 * <Link href="/about">About</Link>
 * <Link href="/about" basic={false}>About</Link>
 * <Link href="/about" onClick={() => console.log('clicked')}>About</Link>
 * <Link href="/about" className="text-primary-500">About</Link>
 * <Link href="/about" className="text-primary-500" basic={false}>About</Link>
 */
export default Link

/**
 * Wraps children in a Link if either href or onClick are provided
 *
 * @param {Object} props Component props
 * @param {Boolean} props.basic Sets some basics styles for a link (like the underline and primary color)
 * @param {node} props.children Link's childen
 * @param {String} props.className Additional classes to be provided
 * @param {String} props.href Link's target url
 * @param {Function} props.onClick link's event on click
 * @param {String} props.target link's target
 * @returns {React.ReactElement}
 */
export function ConditionalLink({
  basic,
  children,
  className = '',
  href,
  onClick,
  target,
}) {
  return (
    <ConditionalWrap
      condition={!!(href || onClick)}
      ifRender={children => (
        <Link
          href={href}
          onClick={onClick}
          className={className}
          basic={basic}
          target={target}
        >
          {children}
        </Link>
      )}
      elseRender={children => <span className={className}>{children}</span>}
    >
      {children}
    </ConditionalWrap>
  )
}
Link.Conditional = ConditionalLink
Link.Conditional.displayName = 'Link.Conditional'

/**
 * Link to an external url
 * @param {Object} props Component props
 * @param {Boolean} props.basic - Sets some basics styles for a link (like the underline and primary color)
 * @param {node} props.children - Link's childen
 * @param {String} props.className - Additional classes to be provided
 * @param {String} props.href - Link's target url
 * @param {Function} props.onClick - link's event on click
 * @returns {React.ReactElement}
 */
export function ExternalLink({
  basic,
  children,
  className = '',
  href,
  onClick,
}) {
  return (
    <Link
      basic={basic}
      className={`inline-flex flex-row items-center space-x-2 ${className}`}
      href={href}
      onClick={onClick}
    >
      <span>{children}</span>
      <Icon name="external-link-square" />
    </Link>
  )
}
Link.External = ExternalLink
Link.External.displayName = 'Link.External'

/**
 * Link to download a file
 * @param {Object} props Component props
 * @param {String} props.className Additional classes to be provided
 * @param {String} props.label The link label
 * @param {String} props.href The link target
 * @param {Boolean} props.showIcon Whether to show the download icon
 * @returns {React.ReactElement}
 */
export function DownloadLink({ className = '', label, href, showIcon = true }) {
  const { t } = useTranslation()

  return (
    <a
      className={`inline-flex items-center gap-2 font-sans text-primary-500 hover:text-primary-400 hover:underline focus:text-primary-400 focus:underline focus:outline-none ${className}`}
      href={href}
      target="_blank"
      rel="noreferrer"
      download
    >
      {showIcon && <Icon name="download" />}
      <span>{label || t('download')}</span>
    </a>
  )
}
