import { useMemo } from 'react'

/**
 * @typedef {Object} ClickableProps The useClickable hook output.
 * @property {String} className The component extra classes, including cursor-pointer
 * @property {String} onClick The onClick event.
 * @property {String} role The component role.
 * @property {Number} tabIndex The tabIndex value for the clickable element.
 * @property {Function} onKeyDown The onKeyDown event.
 */

/**
 * A hook to provide props for basic click functionality to any element, making it compatible with [a11y](https://www.w3.org/WAI/standards-guidelines/wcag/).
 *
 * @param {Object} options The hook options.
 * @param {String} [options.className=''] The component extra classes.
 * @param {String} [options.clickableClass=''] The component extra classes, but only if is clickable.
 * @param {String} [options.keyCode='Enter'] Pressed key code expected to trigger the onClick event.
 * @param {Function} options.onClick Provided onClick event.
 * @param {'link'|'button'} [options.role='link'] The component role (default: 'link').
 * @param {Boolean} [options.withClassName=true] Defines if the className prop should be provided.
 * @returns {ClickableProps} The clickable props object
 */
export default function useClickable({
  className = '',
  clickableClass = '',
  keyCode = 'Enter',
  onClick,
  role = 'link',
  withClassName = true,
}) {
  return useMemo(() => {
    const clicableProps = {
      // Add the role of the element (default: 'link', read more: https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles)
      role,
      // Make the element focusable and interactive
      tabIndex: 0,

      // The onClick event (we just pass the provided onClick)
      onClick,

      // Keyboard support for the clickable element
      onKeyDown: event => {
        if (event.key === keyCode) {
          onClick(event) // Trigger the onClick event when the key is pressed
        }
      },
    }

    if (withClassName) {
      // Add the cursor-pointer class to the element for better UX
      clicableProps.className = `cursor-pointer ${className} ${clickableClass}`
    }

    // Return the clickable props
    return clicableProps
  }, [className, clickableClass, keyCode, onClick, role, withClassName])
}
