import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { useUILanguage } from '@config/i18n'

import useCurrentUser from '../helpers/useCurrentUser'

/**
 * Sets users preferred language for the UI
 * @returns {object} { language, setLanguage }
 */
export function useSetUserLanguage() {
  const { i18n } = useTranslation()
  const { language, setLanguage } = useUILanguage()
  const { user } = useCurrentUser()
  const userLanguage = user?.preferences?.language

  useEffect(() => {
    // If the user has a language preference, and it is not the same as the current language
    if (userLanguage && userLanguage !== language) {
      setLanguage(userLanguage) // Save the preferred language
      i18n.changeLanguage(userLanguage) // Change the language in the UI
    }
  }, [i18n, language, setLanguage, userLanguage])

  // return the language
  return { language }
}

export default useSetUserLanguage
